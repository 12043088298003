.table-button {
  border-radius: 16px;
  width: 132px;
  height: 32px;
  color: #3ca0f5;
  border-color: #3ca0f5;
}

.table-button[disabled] {
  color: #a9b3cb;
  border: none;
  background-color: #f0f3ff;
}

.table-button:not([disabled]) {
  cursor: pointer;
}