.list-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

.list-table .table-row,
.list-table .table-head-row {
  display: flex;
  padding-left: 5%;
}

.list-table .table-head-th,
.list-table .table-body-td {
  min-width: 160px;
}

.list-table .table-head-th.error,
.list-table .table-body-td.error {
  min-width: 50px;
  max-width: 110px;
  width: 110px;
}

.error-icon {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.list-table .table-body-td {
  flex: 1;
  text-align: left;
}

.table-cell {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding-right: 20px;
}

.LinkToLicenseesor.licensor td.actions {
  width: 235px;
}

td.actions {
  display: flex;
}

td.actions .actionButtonsContainer + .actionButtonsContainer {
  margin-left: 5px;
}