.dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-menu .dropdown-menu-head-button {
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown-menu .dropdown-menu-head-button .dropdown-menu-title {
  font-size: 14px;
  font-weight: bold;
}

.dropdown-menu .dropdown-menu-head-button .dropdown-menu-arrow {
  width: 8px;
  margin-left: 8px;
}

.dropdown-menu .dropdown-menu-options-list {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  position: absolute;
}

.dropdown-menu .dropdown-menu-options-list .dropdown-menu-options-list-item {
  width: 100%;
  border: none;
  background: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}