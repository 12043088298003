.text-area-container .text-area {
  overflow-wrap: normal;
  white-space: nowrap;
  max-width: 100%;
  min-width: 100%;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  font-size: 14px;
  line-height: 33px;
  padding-top: 6px;
  padding-left: 30px;
  padding-right: 30px;
  background-attachment: local;
  background-image:
    linear-gradient(to right, white 30px, transparent 30px),
    linear-gradient(to left, white 30px, transparent 30px),
    repeating-linear-gradient(white, white 32px, #e4e4e4 32px, #e4e4e4 33px, white 33px);
}

.text-area-container.single-value .text-area {
  white-space: normal;
}

.text-area-container .text-area.not-valid {
  border-color: rgba(255, 0, 0, 0.466);
}