.checkbox-container {
  font-size: 12px;
  color: #847a9b;
  display: inline-flex;
  white-space: nowrap;
  width: 100%;
}

.checkbox-container input[type="checkbox"] {
  opacity: 0;
}

.checkbox-container .checkbox-label-container {
  display: inline-block;
  white-space: normal;
  position: relative;
  margin-left: -18px;
  padding-left: 26px;
  max-width: 100%;
  cursor: pointer;
}

.checkbox-container .checkbox-label-container .main-label::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: white;
  border: solid 1px rgba(112, 112, 112, 0.17);
}

.checkbox-container .checkbox-label-container .main-label::after {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 2px;
  background-color: #407ceb;
}

.checkbox-container .checkbox-label-container .main-label {
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  vertical-align: middle;
  line-height: 18px;
}

.checkbox-container .checkbox-label-container .main-label--head {
  font-weight: bold;
}

.checkbox-container .checkbox-label-container .main-label::before,
.checkbox-container .checkbox-label-container .main-label::after {
  position: absolute;
}

.checkbox-container .checkbox-label-container .main-label::before {
  top: 0;
  left: 0;
}

.checkbox-container .checkbox-label-container .main-label::after {
  top: 4px;
  left: 4px;
}

.checkbox-container input[type="checkbox"] + .checkbox-label-container .main-label::after {
  content: none;
}

.checkbox-container input[type="checkbox"]:checked + .checkbox-label-container .main-label::after {
  content: "";
}

.checkbox-container input[type="checkbox"]:focus + .checkbox-label-container .main-label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.sub-labels-list ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  cursor: pointer;
  padding-bottom: 20px;
}

.checkbox-container .checkbox-label-container .sub-labels-list .sub-label {
  padding-left: 18px;
  padding-right: 18px;
}

.notification-wrapper {
  display: flex;
  align-items: center;
}

.notification-wrapper .checkbox-container {
  display: block;
  width: 120px;
  margin-left: 20px;
}

.notification-wrapper .checkbox-container .checkbox-label-container {
    /* margin-left: 0; */
}

.notification-wrapper .checkbox-container .checkbox-label-container .main-label {
    margin-bottom: 0;
}

.notification-wrapper .checkbox-container .checkbox-label-container .main-label::before {
  top: 2px;
}

.notification-wrapper .checkbox-container .checkbox-label-container .main-label:after {
  left: 3px;
  top: 5px;  
}
