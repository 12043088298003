.text-input-container .text-input {
  width: 100%;
  height: 33px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
}

.text-input-container .text-input.not-valid {
  border-color: rgba(255, 0, 0, 0.466);
}
