.add-person-information-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.add-person-information-button-plus {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: #407ceb;
  border-radius: 50%;
  padding: 7px;
}

.add-person-information-button-plus img {
  width: 14px;
}

.add-person-information-button-text {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
  color: #407ceb;
  margin-left: 8px;
}

.add-person-information-form-buttons button {
  width: 98px;
  margin-right: 12px;
}