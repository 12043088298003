.sub-section-wrapper {
  margin-bottom: 36px;
}

.sub-section-wrapper-main {
  display: flex;
}

.data-capture-section-column {
  flex: 1;
}

.data-capture-section-form-column {
  margin-right: 45px;
}