.data-capture-section-container {
  margin-bottom: 55px;
}

.data-capture-section {
  width: 100%;
  padding: 20px 0px 80px 20px;
  border-radius: 8px;
  background-color: #ffffff;
}

.data-capture-section h2{
  min-height: 38px;
}

.errors-wrapper {
  margin-bottom: 30px;
}

.alert-error {
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-error + .alert-error {
  margin-top: 10px;
}

.pending-users.data-capture-section .BasicTable .container {
  padding: 0;
}
.pending-users.data-capture-section .BasicTable .container table td:nth-child(2){
  width: 200px;
}

.data-capture-section .flex-xs-grow-1 {
  flex-grow: 0.6;
}

.data-capture-section .text-input-container,
.data-capture-section .text-area-container,
.data-capture-section .radio-button-group,
.data-capture-section .dropdown-container {
  margin-bottom: 22px;
}