.login {
  background-color: #f6f7fb;
  min-height: 100vh;
}

.login-main-form {
  width: 640px;
  border-radius: 8px;
  box-shadow: 0 3px 12px 0 rgba(49, 79, 160, 0.08);
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  padding: 60px 80px 50px;
}

.login-main-form h1 {
  font-family: Lato;
  font-size: 30px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  text-align: center;
}
