.category-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: dashed 1px #ccc;
  margin-bottom: 20px;

  .category-item-wrap {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    + .category-item-wrap {
      margin-top: 15px;
      padding-top: 15px;
      border-top: dashed 1px #ccc;
    }

    h4 {
      margin: 5px 0;
      font-size: 16px;
    }

    .category-children {
      padding-left: 10px;
      margin-left: 10px;
      border-left: solid 1px;
      margin-bottom: 15px;

      h5 {
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }
}

.product-categories-levels-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  gap: 10px;

  h4 {
    font-size: 14px;
    padding: 5px 10px;
    background-color: #eaeaea;
  }

  .product-categories-levels {
    display: flex;
    flex-direction: column;

  }

  .add-more-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .input-wrapper {
    flex-grow: 4;
  }

  .button-wrapper {
    flex-grow: 1;
  }
}

.d-tree-container {
  list-style: none;
  padding: 0;
}

.d-tree-node {
  padding: 0.25rem 0.25rem;

  &.has-child {
    margin-bottom: 10px;
    cursor: pointer;
  }

  .node-icon {
    margin-right: 5px;

    img {
      width: 14px;
      height: auto;
    }
  }

  .d-tree-content {

    .d-tree {
      border-left: dashed 1px #ccc;
    }

    > ul.d-tree-container {
      padding-left: 20px;


    }
  }
}

.node-action-icon {
  img {
    width: 14px;
    height: 14px;
    margin: 0;
  }
}

.d-tree-toggler.active {
  transform: rotate(90deg);
}

.c-pointer {
  cursor: pointer;
}


.category-list-display-builder {
  .tree-wrapper {
    border-radius: 5px;
    border: dashed 1px #ccc;
    margin-bottom: 20px;
    padding: 15px 5px;

    .add-new-category-action {
      padding-left: 30px;
      margin-left: 20px;
      border-left: dashed 1px #ccc;

      .btn.add {
        padding: 0.15rem 0.5rem;
      }
    }
  }

  .category-action {

  }
}


.category-header {
  display: grid;
  grid-template: 'title action';
  grid-template-columns: auto 200px;
  margin-bottom: 15px;
}

.d-tree-head {
  align-items: center;
  margin-bottom: 5px;
  display: flex;

  .node-remove-action {
    margin-left: 5px;
    background: #eee;
    border: 0;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    display: inline-flex;
    border-radius: 50%;

    &:hover {
      background-color: #ddd;
    }

    img {
      height: 16px;
    }
  }
}

.autocomplete-input-wrapper {
  position: relative;
  //z-index: 3;

  .category-drop {
    position: absolute;
    top: 100%;
    padding: 10px 0;
    box-shadow: 0 0 2px transparentize(#000, 0.8);
    background: #fff;
    border-radius: 3px;
    max-height: 160px;
    overflow: auto;
    z-index: 4;

    .drop-list {
      display: flex;
      flex-direction: column;

      .drop-list-item {
        transition: 0.2s;
        padding: 3px 10px;

        &:hover {
          cursor: pointer;
          background-color: #eee;
        }
      }
    }
  }
}
