.xelacore-header-main {
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xelacore-header-main-logo {
  display: inline-block;
  position: relative;
  width: 146px;
  height: 42px;
}

.xelacore-header-main-text {
  font-size: 12px;
  color: #a69cbe;
  position: absolute;
  top: 27px;
  left: 40px;
}

