.menulist {
  background-color: #ffffff;
  top: 38px;
  left: 0;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px rgba(112,112,112, .2);
}
.menulist__container {
  padding: 15px 20px;
  overflow-y: scroll;
}
.menulist__container__selected_count {
  padding: 0 10px 10px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  color: #3b86ff;
}
.menulist__search {
  position: relative;
}
.menulist__search__field {
  width: 100%;
  height: 27px;
  border-radius: 17px;
  border: solid 1px #e0ecff;
  background-color: #f7faff;
  padding: 6px 21px;
  font-size: 12px;
  margin-bottom: 10px;
}
.menulist__search__field::placeholder {
  color: rgba(56,105,173,.5);
  font-style: italic;
}
.menulist__search__icon {
  position: absolute;
  right: 10px;
  top: 4px;
}
.menulist__footer {
  height: 44px;
  background-color: #f7faff;
  padding: 16px;
}
.menulist__footer__select_all {
  width: 63px;
  height: 14px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #3b86ff;
  text-decoration: underline;
  cursor: pointer;
}
.menulist__results__group {
  border: solid 1px #e7e7e7;
  border-radius: 6px;
  margin-bottom: 7px;
}
.menulist__results__group__title {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}
.menulist__results__group__title__name .checkbox-container {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #302658;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}
.menulist__results__group__title__selected_count {
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  color: #3b86ff;
  display: flex;
  align-items: flex-end;
  padding-bottom: 1px;
}
.menulist__results__group__title__arrow {
  position: absolute;
  right: 10px;
  top: 15px;
}
.menulist__results__group__title__arrow__triangle {
  position: relative;
  top: -7px;
  margin: 0 0 0 10px;
  display: inline-block;
  width: 0;
  height: 0;
  transition: transform ease 0.3s;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000000;
}
.menulist__results__group__title__arrow__triangle.expanded {
  transform: rotate(180deg);
}
.menulist__results__group__title.expanded {
  background-color: #f5f4f7;
}
.menulist__results__items {
  padding: 10px 0;
}
.menulist__results__items__item .checkbox-container {
  height: 33px;
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #302658;
  display: flex;
  justify-content: flex-start;
}
.menulist__results__items__item.single {
  cursor: pointer;
}
.menulist__results__items__item.single:hover {
  background-color: #f5f4f7;
}
.menulist__results__items__item.selected {
  background-color: #e0ecff;
}
.menulist__results__items__item.last_selected {
  border-bottom: 1px solid #e7e7e7;
}
.menulist__results input[type=checkbox] {
  margin-right: 10px;
  display: inline;
}
.menulist__results label {
  opacity: 1;
}

.menulist .checkbox-container {
  width: auto;
}

.menulist .checkbox-container .checkbox-label-container .main-label::before {
  top: 2px;
}

.menulist .checkbox-container .checkbox-label-container .main-label::after {
  top: 6px;
}
