.data-capture-page-nav-item,
.data-capture-page-nav-item.selected ~ .data-capture-page-nav-item.selected {
  padding: 6px 13px;
  font-size: 16px;
  margin-bottom: 6px;
  cursor: pointer;
  color: #1a1a67;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-weight: normal;
}

.data-capture-page-nav-item.selected,
.data-capture-page-nav-item:hover {
  border-left: 4px solid #407ceb;
  padding-left: 9px;
  font-weight: bold;
  color: #407ceb;
}
