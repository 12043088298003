.org-admin-nav-header-type-banner {
  border-radius: 16px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: white;
  margin-bottom: 10px;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  text-align: center;
  line-height: normal;

}