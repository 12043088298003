.list-nav-bar {
  background-color: #ffffff;
  border-radius: 18px;
  padding-left: 22px;
  padding-right: 22px;
}

.list-nav-bar a {
  display: inline-block;
  font-size: 14px;
  color: #1f1f1f;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 96px;
  text-align: center;
  margin-right: 12px;
  text-decoration: none;
}

.list-nav-bar a:hover {
  border-bottom: 3px solid rgba(232, 64, 122, 0.5);
}

.list-nav-bar a.active {
  border-bottom: 3px solid rgb(232, 64, 122);
  font-weight: bold;
}
