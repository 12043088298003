.data-capture-page-nav-item,
.data-capture-page-nav-item.in-view ~ .data-capture-page-nav-item.in-view {
  padding: 6px 13px;
  font-size: 16px;
  margin-bottom: 6px;
  cursor: pointer;
  color: #1a1a67;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-capture-page-nav-item.in-view,
.data-capture-page-nav-item:hover {
  border-left: 4px solid #407ceb;
  padding-left: 9px;
  font-weight: bold;
  color: #407ceb;
}
