.module-list {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.module-list-item {
    display: grid;
    grid-template: 'action name';
    grid-template-columns: 44px auto;
    align-items: center;
    padding: 5px 10px;
    margin-left: -10px;
    margin-right: -10px;
    grid-gap: 15px;
    cursor: pointer;
    transition: 0.4s;
}

.module-list-item:hover{
    background-color: #fafafa;
}

.fake-checkbox-switcher label {
    position: relative;
    width: 44px;
    margin: 0;
    height: 24px;
}

.fake-checkbox-switcher label input {
    opacity: 0;
    width: 0;
    height: 0;
}

.fake-switcher {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
}

.fake-switcher:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .fake-switcher {
    background-color: #407ceb;
}

input:disabled + .fake-switcher {
   opacity: 0.5;
}


input:checked + .fake-switcher:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
