// Override default variables before the import

html, body {
  margin: 0;
  padding: 0;
}

html {
  background-color: rgba(247, 248, 249, 1) !important;
}

body {
  height: 100%;
  min-height: 100%;
}

.x-background {
  background-color: rgba(247, 248, 249, 1);
}

.nav-link {
  &.pill {
    border-radius: 20px;
    background: rgba(226, 246, 247, 1);
    color: #333333;
    display: block;
    padding: 0.5rem 1rem;

    &:hover {
      background: darken(rgba(226, 246, 247, 1), 8%);
      color: #333333;
      transition: all 0.3s ease;
    }

    &.active {
      background: darken(rgba(226, 246, 247, 1), 8%);
    }
  }
}

.form-label {
  color: #7F8184;
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 0;
}

form {
  &.add_user {
    border-right: 1px solid #cccccc;
    margin-right: 0;
  }
}

.btn {

  /* Drop shadow bug in chrome reqs following */
  &:focus,
  :active,
  .active,
  :focus:active {
    outline: 0;
    outline: none;
    box-shadow: none;
  }

  &.submit {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    background-image: linear-gradient(to left, #4047eb, #941dc7);
    font-size: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(to left, darken(#4047eb, 8%), darken(#941dc7, 8%));
      transition: all 0.3s ease;
    }

    &:focus,
    :active,
    .active,
    :focus:active {
      background-color: linear-gradient(to left, #4047eb, #941dc7);
      box-shadow: none;
    }
  }

  &.add {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    background-color: rgba(1, 198, 211, 1);
    font-size: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: darken(rgba(1, 198, 211, 1), 8%);
      transition: all 0.3s ease;
    }

    &:focus,
    :active,
    .active,
    :focus:active {
      background-color: rgba(1, 198, 211, 1);
      box-shadow: none;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
      background-color: rgba(1, 198, 211, 1);
    }
  }

  &.delete {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    background-color: #dc3545;
    font-size: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: darken(#dc3545, 8%);
      transition: all 0.3s ease;
    }

    &:focus,
    :active,
    .active,
    :focus:active {
      background-color: #dc3545;
      box-shadow: none;
    }
  }

  &.notify {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    font-size: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: darken(#007bff, 8%);
      transition: all 0.3s ease;
    }

    &:focus,
    :active,
    .active,
    :focus:active {
      background-color: #007bff;
      box-shadow: none;
    }
  }

  &.cancel {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    background-color: #6c757d;
    font-size: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: darken(#6c757d, 8%);
      transition: all 0.3s ease;
    }

    &:focus,
    :active,
    .active,
    :focus:active {
      background-color: #6c757d;
      box-shadow: none;
    }
  }

  &.disabled {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    background-color: #7c7c7c;
    font-size: 14px;
    color: #ffffff !important;
    border: none;
    cursor: default;
  }
}

h2.sub-section-title {
  clear: both;
  height: 2em;
}

.fa-chevron-down {
  display: block;
  margin: 0.2em auto 0 1em;
  float: left;
}

.fa-chevron-up {
  display: block;
  margin: 0.2em auto 0 1em;
  float: left;
}

.email-wrapper {
  display: flex;
  align-items: center;

  .email-filter-box {
    width: auto;
    min-width: 150px;
    padding: 3px 2px 3px 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
    vertical-align: none;
    text-align: left;
    text-transform: none;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #cccccc;
    margin-right: 0.5rem;
    margin-left: 10px;
  }
}

.x-filter-bar {
  height: 40px;
  margin-bottom: 5px;
  margin-right: 0;
  clear: right;
  float: right;
}

.x-filter-bar-search-icon {
  height: 20px;
  margin-top: 10px;
  margin-right: 5px;
}

#x-filter-bar-search-box {
  width: 250px;
  height: 40px;
  padding: 3px 2px 3px 10px;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #000000;
  vertical-align: none;
  text-align: left;
  text-transform: none;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid #cccccc;
  margin-right: 0.5rem;
}

.form-group {
  &.gcp_list {
    width: 450px;
  }

  .form-item {
    + .form-item {
      margin-top: 15px;
    }
  }
}

textarea {
  /* will prevent resizing horizontally */
  resize: vertical;
}

.page-nav-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: none;
  border-bottom: 1px solid rgba(218, 217, 223, 1);
  padding: 20px;
  background-color: #eff2fd;
  min-height: 100px;

  &.header-text {
    font-size: 20px;
    text-align: left;
    font-weight: normal;
    padding-bottom: 10px;
    font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;
    color: #333333;
  }
}

.admin-nav-item {
  padding: 6px 13px;
  font-size: 16px;
  margin-bottom: 6px;
  cursor: pointer;
  color: #1a1a67;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-weight: normal;

  &.admin-nav-item.selected, .admin-nav-item:hover {
    border-left: 4px solid #407ceb;
    padding-left: 9px;
    font-weight: bold;
    color: #407ceb;
  }
}

.contactCardToggle {
  cursor: pointer;
}

.version-number {
  position: fixed;
  bottom: 0;
  margin-left: 0.5em;
  clear: left;
  float: left;
  font-size: 0.8em;
  color: #cccccc;
  background-color: rgba(247, 248, 249, 1) !important;
  display: block;
}

.actionButtonsContainer {
  display: inline-block;
  margin-right: 1px;
  margin-bottom: 2px;
}

.companyInfoBlock {
  margin-top: 10px;

  .actions-row {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 20px 0;
  }
}

input.form-control {
  &::placeholder {
    color: #999;
    opacity: 1;
  }
}

.export-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-actions {
  display: flex;
  align-items: center;

  .error-message {
    color: #ff4747;
    font-size: 14px;
  }
}

.link-text {
  color: #407ceb;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.rejected {
    color: #ff4747;
  }
}

.status {
  text-transform: capitalize;

  &.completed, &.success {
    color: #5db55a;
  }

  &.failed, &.rejected {
    color: #ff4747;
  }

  &.pending, &.running {
    color: #ffa52c;
  }
}

.export-update, .last-logins {
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.refresh-wrapper {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 10px;
}

.refresh-button {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px 20px;

  &:active {
    background-color: #eee;
    border-color: #ddd;
  }
}

.spin-loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #01c6d3; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  display: inline-flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-bordered td.no-logins {
  padding: 20px;
  text-align: center;
}

.empty-placeholder {
  padding: 20px;
  text-align: center;
  opacity: 0.6;
  font-weight: 300;
}

.fake-checkbox-switcher {

  label {
    position: relative;
    width: 44px;
    margin: 0;
    height: 24px;
  }

  label input {
    opacity: 0;
    width: 0;
    height: 0;
  }


  .fake-switcher {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
  }

  .fake-switcher:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input[value="true"] + .fake-switcher {
    background-color: #407ceb;
  }


  input[value="true"] + .fake-switcher:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}

.table-head-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;

  .filter-box {
    width: auto;
    min-width: 150px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    margin-right: 0.5rem;
    margin-left: 10px;
    height: 40px;
    padding: 3px 20px 3px 13px;
    font-family: 'ArialMT', 'Arial', sans-serif;
    font-size: 13px;
    color: #000000;
    border-radius: 20px;
  }

  .order-icon {
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.no-data {
  height: 100px;
  text-align: center;
  font-style: italic;
  opacity: 0.6;
}

.col-button-wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.org-logo {
  width: 75px;
  height: 75px;
  background-color: #FFFFFF;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    background-color: #FFFFFF;
  }
}

.employees-modal .modal-dialog {
  max-width: 1000px;

  .table-bordered {
    th {
      vertical-align: middle;

      &.checkbox-th {
        width: 50px;
        text-align: center;
      }
    }

    td {
      vertical-align: middle;

      &.checkbox-th {
        width: 50px;
        text-align: center;
      }

      .email-cell {
        position: relative;

        &:hover {
          .custom-tooltip{
            display: block;
          }
        }

        .custom-tooltip {
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 125%;
            width: auto;
            font-size: 12px;
            text-align: center;
            background: #000000a1;
            border-radius: 3px;
            padding: 5px;
            font-weight: normal;
            color: #fff;
            z-index: 1;
            word-break: break-word;

            &:after {
              content: '';
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: #000000a1 transparent transparent transparent;
            }
        }

        .email-td {
          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.filter-input-box {
  position: relative;

  .filter-cleaner {
    position: absolute;
    right: 15px;
    top: 50%;
    height: 14px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -7px;
    cursor: pointer;
  }
}
.fixed-table tr td {
  display: table-cell;
  height: 100%; /* Asigură că celulele ocupă întreaga înălțime a rândului */
}
td.no-data{
  vertical-align: middle !important;
  background-color: #fff !important;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';