.ip-hierarchy-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: dashed 1px #ccc;
  margin-bottom: 20px;

  .ip-hierarchy-item-wrap {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    + .ip-hierarchy-item-wrap {
      margin-top: 15px;
      padding-top: 15px;
      border-top: dashed 1px #ccc;
    }

    h4 {
      margin: 5px 0;
      font-size: 16px;
    }

    .ip-hierarchy-children {
      padding-left: 10px;
      margin-left: 10px;
      border-left: solid 1px;
      margin-bottom: 15px;

      h5 {
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }
}

.ip-hierarchy-levels-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  gap: 10px;

  h4 {
    font-size: 14px;
    padding: 5px 10px;
    background-color: #eaeaea;
  }

  .ip-hierarchy-levels {
    display: flex;
    flex-direction: column;

  }

  .add-more-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .input-wrapper {
    flex-grow: 4;
  }

  .button-wrapper {
    flex-grow: 1;
  }
}

.d-tree-container {
  list-style: none;
  padding: 0;
}

.d-tree-node {
  padding: 0.25rem 0.25rem;

  &.has-child {
    margin-bottom: 10px;
    cursor: pointer;
  }

  .node-icon {
    margin-right: 5px;

    img {
      width: 14px;
      height: auto;
    }
  }

  .d-tree-content {

    .d-tree {
      border-left: dashed 1px #ccc;
    }

    > ul.d-tree-container {
      padding-left: 20px;


    }
  }
}

.node-action-icon {
  img {
    width: 14px;
    height: 14px;
    margin: 0;
  }
}

.d-tree-toggler.active {
  transform: rotate(90deg);
}

.c-pointer {
  cursor: pointer;
}


.ip-hierarchy-list-display-builder {
  .tree-wrapper {
    border-radius: 5px;
    border: dashed 1px #ccc;
    margin-bottom: 20px;
    padding: 15px 5px;

    .add-new-category-action {
      padding-left: 30px;
      margin-left: 20px;
      border-left: dashed 1px #ccc;

      .btn.add {
        padding: 0.15rem 0.5rem;
      }
    }
  }

  .ip-hierarchy-action {

  }
}


.ip-hierarchy-header {
  display: grid;
  grid-template: 'title action';
  grid-template-columns: auto 1fr;
  margin-bottom: 15px;
  gap: 32px;
}

.d-tree-head {
  align-items: center;
  margin-bottom: 5px;
  display: flex;

  .node-remove-action {
    margin-left: 5px;
    background: #eee;
    border: 0;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    display: inline-flex;
    border-radius: 50%;

    &:hover {
      background-color: #ddd;
    }

    img {
      height: 16px;
    }
  }
}


.level-branch-row {
  display: flex;
  border-radius: 5px;
  border: solid 1px #ddd;
  box-shadow: 0 1px 4px 0 transparentize(#000, 0.93);
  margin-bottom: 20px;
  overflow: hidden;

  .level-branch-col {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
      border-right: solid 1px #ddd;
    }

    .level-item-cell-name {
      padding: 15px;
      background: #f9fafb;
      border-bottom: #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        fill: #212529;
      }

      &.active {
        color: #3b82f6;

        svg {
          fill: #3b82f6;
        }
      }

      h4 {
        font-size: 16px;
        margin: 0;
        font-weight: bold;
        line-height: 1;
        display: flex;
        align-items: center;

        span {
          margin-left: 15px;
          font-weight: normal;
          font-size: 13px;
        }
      }

      i {
        font-style: normal;
        font-size: 20px;
      }
    }


    .level-item-branch {
      cursor: pointer;
      padding: 10px 15px;
      transition: 0.2s;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }

      &.active {
        background-color: #e8f1ff;
      }

      &:hover {
        background: #eee;
      }
    }

    .level-item-cell-list {

      &.hidden {
        display: none;
      }
    }
  }
}

.pad-10 {
  padding: 10px;
}

// Search style

.global-search-container {
  position: relative;
  width: 100%;

  .search-input-wrapper {
    position: relative;

    .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      color: #6b7280;
    }

    .global-search-input {
      padding-left: 35px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #d1d5db;
      width: 100%;

      &:focus {
        outline: none;
        border-color: #3b82f6;
        box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
      }
    }
  }

  .search-results-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

    .search-result-item {
      padding: 8px 12px;
      cursor: pointer;

      &:hover {
        background-color: #f3f4f6;
      }

      .result-path {
        font-size: 13px;
        color: #374151;

        span {
          &:last-child {
            font-weight: 500;
            color: #3b82f6;
          }
        }
      }
    }
  }
}


.level-item-cell-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .pad-10 {
    flex-shrink: 0;
  }

  .level-item-branch-wrapper {
    overflow-y: auto;
    max-height: calc(100vh - 380px);

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
