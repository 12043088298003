.radio-button-container {
  font-size: 13px;
  color: #1a1a67;
  display: inline-block;
}

.radio-button-container input[type="radio"] {
  opacity: 0;
}

.radio-button-container label::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 1px rgba(26, 26, 103, 0.35);
}

.radio-button-container label::after {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #3ca0f5;
}

.radio-button-container label {
  position: relative;
  padding-left: 8px;
  padding-right: 16px;
}

.radio-button-container label::before,
.radio-button-container label::after {
  position: absolute;
}

.radio-button-container label::before {
  top: -1px;
  left: -18px;
}

.radio-button-container label::after {
  top: 3px;
  left: -14px;
}

.radio-button-container input[type="radio"] + label::after {
  content: none;
}

.radio-button-container input[type="radio"]:checked + label {
  font-weight: bold;
}

.radio-button-container input[type="radio"]:checked + label::after {
  content: "";
}

.radio-button-container input[type="radio"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
