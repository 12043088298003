button.primary-button {
  border-radius: 16px;
  width: 100%;
  height: 32px;
  box-shadow: 0 5px 12px 0 rgba(73, 128, 232, 0.22);
  background-image: linear-gradient(to left, #4047eb, #941dc7);
  font-size: 12px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}