.table-head {
    /* background-image: linear-gradient(to left, #6a18b2, #4f15b9); */
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 4px;
    -moz-box-shadow: 0px 0px 4px rgba(31, 67, 81, 0.0941176470588235);
    -webkit-box-shadow: 0px 0px 4px rgba(31, 67, 81, 0.0941176470588235);
    box-shadow: 0px 0px 4px rgba(31, 67, 81, 0.0941176470588235);

    border-width: 1px;
    border-style: solid;
    border-color: rgba(218, 217, 223, 1);
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;

    box-sizing: unset;
}

.table-head .table-head-row {
    padding-top: 9px;
    padding-bottom: 9px;
    height: 53px;
}

.table-head-th {
    display: inline-block;
    flex: 1;
}

.table-head-th-icon {
    width: 12px;
}

.table-head-th-input {
    margin-left: 4px;
    margin-right: 5px;
    background: transparent;
    border: none;
    color: black;
    /*width: 100%;*/
    font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px
}

.table-head-th-input::placeholder {
    color: #333333;
    /* text-transform: uppercase;
    font-weight: bold; */
}

.sort-icon {
    cursor: pointer;
}

.sort-icon svg {
    width: 16px;
    height: 16px;
}
