@keyframes dropFadeIn {
  0% {opacity: 0; transform: translateY(-50px);}
  50% {opacity: 1; transform: translateY(20px);}
  100% {transform: translateY(0);}
}

.c-notification {
  z-index: 1060;
  width: 100%;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, .9);
  animation: dropFadeIn .6s;
}

.c-notification--error {
  background: rgba(219, 50, 34, .9);
  color: #f9f9f9;
}

.c-notification--success {
  background: rgba(93, 181, 90, .9);
  color: #fff;
}

.c-notification--info {
  background: rgba(55, 163, 255, .9);
  color: #f9f9f9;
}

.c-notification__notification-text {
  font-size: 14px;
  padding: 10px 20px;
}
