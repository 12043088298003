.data-capture-page-nav {
  width: 250px;
}

.data-capture-page-nav.fixed {
  position: fixed;
  top: 0;
}

.data-capture-page-nav-container {
  width: 270px;
  height: 1px;
}

.data-capture-page-nav-content {
  display: flex;
  padding-top: 17px;
  background-color: #eff2fd;
  min-height: 60vh;
  flex-direction: column;
  justify-content: space-between;
}

.data-capture-page-nav-title {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #417deb;
}

.data-capture-page-nav-list {
  width: 100%;
}

.data-capture-page-nav-controls {
  padding: 20px 19px;
}

.data-capture-page-nav-controls-section {
  width: 100%;
}

.data-capture-page-nav-controls-bottom-section-btn-container {
  width: 50%;
  display: inline-block;
  height: 32px;
  vertical-align: bottom;
  text-align: center;
}

.data-capture-page-nav-controls-bottom-section-btn-container:nth-child(odd) {
  padding-right: 7px;
}

.data-capture-page-nav-controls-bottom-section-btn-container:nth-child(even) {
  padding-left: 7px; 
}
