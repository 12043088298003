.status_label {
  margin: -0.5em 0 0 1em;
  padding: 0.5em;
  color: #ffffff;
  border-radius: 5px;
  cursor: default;

  &.on {
    background-color: #dc3545;
  }

  &.off {
    background-color: #4047eb;
  }
}

.validation-tabulate-wrapper {
  .validation-status {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    align-items: center;

    h4 {
      font-size: 16px;
      margin: 0;
    }

    button {
      height: 32px;
      display: flex;
      border-radius: 3px;
      padding: 0 15px;
      justify-content: center;
      align-items: center;
      border: 0;
      color: #fff;


      &.enabled {
        background-color: #4caf50;
      }
      &.disabled {
        background-color: #9e9e9e;
      }
    }
  }
}