.modalBodyTitle {
    font-size: 12px;
    padding: 10px;
    background: rgba(138, 35, 204, 0.04);
    border-left: 3px solid #4047eb;
}

.modalTitleH4.modal-title {
    font-size: 18px;
}

.cancel.btn.btn-primary {
    background: none;
    color: #000;
}

.cancel.btn.btn-primary:hover {
    background: #eee;
}

.form-control {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.form-control.error {
    border-color: #f44336;
}

.error-label {
    color: #f44336;
    font-size: 12px;
}

.input-with-icon {
    position: relative;
}

.input-with-icon .input-action-icon {
    position: absolute;
    right: 12px;
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;
}

.input-with-icon .input-action-icon svg {
    opacity: 0.3;
    width: 24px;
    height: 24px;
}

.nuke-confirm {
    color: red;
    margin-left: 10px;
}

.fake-checkbox-switcher.nuke-records input[value="true"] + .fake-switcher {
    background-color: red;
}