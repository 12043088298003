.dropdown-select-indicator {
  width: 8px;
  margin-left: 5px;
  margin-right: 8px;
}

.dropdown-container .dropdown-select--is-disabled {
  opacity: 0.7;
}

.dropdown-container .dropdown-select__control {
  border-radius: 5px;
  border: solid 1px #eaeaea;
  background-color: #ffffff;
  max-height: 33px;
  min-height: 33px;
  box-shadow: none;
}

.dropdown-container .dropdown-select__control--is-focused {
  outline: rgb(59, 153, 252) auto 5px !important;
}

.dropdown-container .dropdown-select__control:hover {
  border-color: #eaeaea;
}

.dropdown-container .dropdown-select__placeholder {
  color: rgba(0, 0, 0, .21);
  font-style: italic;
}

.dropdown-container .dropdown-select__value-container {
  padding-left: 14px;
  padding-right: 14px;
}

.dropdown-container .dropdown-select-container {
  font-size: 14px;
}

.dropdown-container .dropdown-select__menu {
  box-shadow: none;
  border: solid 1px #eaeaea;
  margin: 5px 0;
}

.dropdown-container .dropdown-select__menu-notice {
  color: rgba(0, 0, 0, .21);
}

.dropdown-container .dropdown-select__menu-list {
  padding: 0;
  border-radius: 5px;
}

.dropdown-container .dropdown-select__option {
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown-container .dropdown-select__option:active {
  background-color: #e5eaff;
}

.dropdown-container .dropdown-select__option,
.dropdown-container .dropdown-select__value-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-container .dropdown-select__option--is-focused {
  background-color: #f0f3ff;
}

.dropdown-container .dropdown-select__option--is-selected {
  background-color: #e5eaff;
  color: black;
}

.dropdown-container .dropdown-select__option,
.dropdown-container .dropdown-select__control {
  cursor: pointer;
}

.dropdown-container .dropdown-select__multi-value {
  min-width: 60px;
  justify-content: space-between;
  background-color: #e5eaff;
}

.dropdown-container .dropdown-select__multi-value .dropdown-select__multi-value__remove:hover {
  color: #407ceb;
  background-color: #e3edff;
}

.dropdown-container .dropdown-select__clear-indicator {
  padding-left: 1px;
  padding-right: 1px;
  width: 18px;
  height: 32px;
}

.dropdown-container .dropdown-select__clear-indicator svg {
  position: relative;
  top: -2px;
}

.dropdown-container .dropdown-select__value-container.dropdown-select__value-container--is-multi {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  padding-left: 4px;
}
