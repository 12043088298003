.table-row-link {
  text-decoration: none;
  color: inherit;
}

.table-row {
  font-size: 14px;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(218, 217, 223, 1);
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.list-table-body .table-row:hover {
}

.table-row-link:first-of-type .table-row {
}

.table-row .table-cell {
  height: 89px;
}

.table-row .table-cell .table-cell-value {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.aliases-list{
  padding-left: 5px;
  list-style: none;
  margin: 0;
}