.data-capture-page-nav-header {
  font-size: 16px;

  width: 100%;
  background: none;
  border: none;

  border-width: 1px;
  border-style: solid;
  border-color: rgba(218, 217, 223, 1);
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;

  text-align: left;
  font-weight: normal;

  display: flex;
  padding-top: 17px;
  background-color: #eff2fd;
  min-height: 80px;
  flex-direction: column;
  justify-content: space-between;
}

.data-capture-page-nav-header-text {
  padding-bottom: 10px;
  width: 100%;
  font-size: 2vmin;
  font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
  /* font-size: 20px; */
  letter-spacing: normal;
  color: #333333;
  line-height: normal;
}

.header-text{
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}