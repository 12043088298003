@import './styles/fonts.css';

.x-section-heading {
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
}

* {
  /* box-sizing: border-box; */
  /* font-family: Lato;*/
  font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif; 
}