.x-organisation-filter-bar {
  height: 40px;
  margin-bottom: 5px;
}

.x-add-org-button {

}

.x-filter-bar-search-icon {
  height: 20px;
  margin-top: 10px;
  margin-right: 5px;
}

.x-user-actions-drop-down-icon {
  margin-left: 10px;
}

#user-actions-dropdown {
  font-family: 'Roboto-Medium', 'Roboto Medium', 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  /* font-size: 14px; */
  text-align: left;
  padding-top: 0px;
  padding-bottom: 0px;
  color: black;
  border-width: 0px;
  background-color: transparent;
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
