.category-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: dashed 1px #ccc;
  margin-bottom: 20px;

  .category-item-wrap {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    + .category-item-wrap {
      margin-top: 15px;
      padding-top: 15px;
      border-top: dashed 1px #ccc;
    }

    h4 {
      margin: 5px 0;
      font-size: 16px;
    }

    .category-children {
      padding-left: 10px;
      margin-left: 10px;
      border-left: solid 1px;
      margin-bottom: 15px;

      .licensor-item-row {
        display: grid;
        grid-template-areas: 'name action';
        grid-template-columns: auto 200px;
        padding: 10px 0;
        align-items: center;
      }

      .licensor-item-action {

      }

      h5 {
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }
}

.d-tree-head-row {
  display: grid;
  grid-template: 'info name action';
  grid-template-columns: 20px auto 280px;
  align-items: center;

  .licensor-category-action {
    font-size: 12px;


    .select-suggested-selected {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
      position: relative;

      .select-holder {
        flex-grow: 1;
        width: 100%;
      }

      .suggested__select {
        box-shadow: 0 0 10px #FD7E14FF;
        border-radius: 5px;
      }

      .suggestion-info {
        display: none;
        position: absolute;
        right: 100%;
        top: 50%;
        margin-top: -9px;
        margin-right: 10px;

        .suggestion-tooltip {
          position: relative;

          .suggestion-tooltip {
            display: none;
            position: absolute;
            right: 100%;
            width: 240px;
            margin-left: -100px;
            font-size: 12px;
            text-align: center;
            margin-right: 5px;
            top: 0;
            margin-top: -15px;
            background: #ffe6d2;
            border-radius: 3px;
            padding: 5px;
            border: solid 1px #FD7E14FF;
            color: #FD7E14FF;
            z-index: 1;

            &:hover {
              display: block;
            }
          }

          .suggestion-icon:hover + .suggestion-tooltip {
            display: block;
          }

        }
      }

      &.suggested__option-type {
        .suggestion-info {
          display: block;
        }
      }
    }
  }
}


.tree-wrapper {
  .mapping-control {
    display: flex;
    gap: 10px;

    .mapping-control-tab {
      padding: 10px;
      background-color: #eee;
      color: #555;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.9;
      }

      &.active {
        background-color: #01c6d3;
        color: #fff;
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .mapping-display {
    padding-top: 20px;

  }
}
