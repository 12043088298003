.data-capture-table-column-header {
  margin-top: 3px;
}

.data-capture-table-column-header-icon {
  width: 15px;
}

.data-capture-table-column-header-title,
.data-capture-table-column-header-count {
  font-size: 14px;
  color: #38315f;
}

.data-capture-table-column-header-title {
  font-weight: bold;
  margin-left: 8px;
  margin-right: 4px;
}

.data-capture-table-column-header-remove {
  color: #e50000;
  font-size: 12px;
  border: none;
  cursor: pointer;
  vertical-align: text-bottom;
  background: none;
}

.data-capture-table-column-header-left-main {
  display: inline-flex;
}

.data-capture-table-column-header-left-bottom {
  margin-top: -2px;
  font-size: 12px;
  color: #ff7f00;
  margin-left: 24px;
}

.data-capture-table-column-header-left,
.data-capture-table-column-header-right {
  display: inline-block;
}

.data-capture-table-column-header-right {
  height: 17px;
  float: right;
}

.data-capture-table-chars-count {
  font-weight: bold;
  color: #393260;
}