.label-container {
  margin-bottom: 6px;
}

.label-container .label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  color: #a59ab7;
  text-transform: uppercase;
}

.label-container .label-warning-sign {
  float: right;
  font-size: 12px;
  line-height: 24px;
  color: #ff8900;
}