.pagination {
  display: inline-block;
  font-size: 12px;
}

.pagination-container {
  text-align: center;
}

.pagination button {
  position: relative;
  cursor: pointer;
  border: none;
}

.pagination button:focus {
  z-index: 2;
}

.pagination-side-button {
  width: 52px;
  border-radius: 4px;
  background-color: #121e36;
  color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
}

.pagination-side-button[disabled] {
  background-color: #e5e5e5;
  cursor: default;
}

.pagination-page-button,
.pagination-page-cells-dots {
  display: inline-block;
  color: #716e89;
  background-color: #ffffff;
  text-align: center;
  width: 32px;
  height: 23px;
}

.pagination-page-button,
.pagination-side-button {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
}

.pagination-page-button--current {
  color: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-image: linear-gradient(78deg, #407ceb, #941dc7);
}

.pagination-page-cells-dots {
  cursor: default;
}

.pagination-page-cells-before-current,
.pagination-page-cells-after-current {
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 5px 12px 0 rgba(73, 128, 232, 0.1);
}

.pagination-page-cells-before-current {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination-page-cells-after-current {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
