.data-capture-table-column {
  padding: 12px;
  border: 1px solid #f6f7fb;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.data-capture-table-column-add-data {
  margin-top: 11px;
  margin-bottom: 11px;
  border-radius: 5px;
}

.data-capture-table-column-data-list {
  overflow-x: hidden;
  display: flex;
  flex: 1;
}

.data-capture-table-column-data-list .checkbox-group-list {
  flex: 1;
  overflow-x: auto;
}

.data-capture-table-column-data-list .checkbox-group-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

