.data-capture-page-form-wrapper{
  background-color: #f6f7fb;
  display: inline-block;
  min-width: 100%;
}

.data-capture-page-form-wrapper-main {
  padding: 10px 62px 0;
  display: flex;
}

.data-capture-page-form-wrapper-nav {
  width: 250px;
  margin-right: 20px;
  float: left;
}

.data-capture-page-form-wrapper-content {
  width: 100%;
}
