.login-form {
  padding: 0 10px;
}

.login-form .login-form-inputs {
  margin-top: 70px;
}

.login-form .login-form-inputs .login-form-text-input {
  border-width: 0 0 1px;
  border-color: #d0d0d0;
  width: 100%;
  font-family: Lato;
  font-size: 16px;
  color: #4c4d58;
  padding-top: 8px;
  padding-bottom: 8px;
}

.login-form-remember-me {
  margin-top: 24px;
}

.login-form-submit-button {
  margin-top: 50px;
  width: 112px;
}

.login-form .login-form-inputs input[type="password"].login-form-text-input {
  margin-top: 36px;
}

.login-form input.login-form-text-input.not-valid {
  border-color: rgba(255, 0, 0, 0.466);
}
