.expandable-textarea {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.expandable-textarea-inner {
  background-color: #f0f4ff;
}

.expandable-textarea-controls {
  background-color: #e3edff;
  overflow: auto;
  height: 58px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable-textarea-controls-add-btn,
.expandable-textarea-controls-state-btn {
  border: none;
  cursor: pointer;
  background: none;
}

.expandable-textarea-controls-add-btn {
  font-weight: bold;
  color: #3ca0f5;
  text-align: left;
}

.expandable-textarea-controls-state-btn {
  color: #474d6f;
}

.expandable-textarea-controls-state-icon {
  width: 8px;
  margin-left: 4px;
}

.expandable-textarea-controls-state-icon--rotated {
  transform: rotate(180deg)
}

.expandable-textarea-inner-textarea {
  max-width: 100%;
  min-width: 100%;
  background: transparent;
  border: none;
  min-height: 200px;
  padding: 14px;
  color: #474d6f;
  font-weight: bold;
  overflow-wrap: normal;
}
