.xelacore-header-general {
  padding: 34px;
}

.xelacore-header-general img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 145px;
}
