.checkbox-group-option {
  padding: 6px 18px;
}

.checkbox-group-option.is-checked {
  background-color: #e5eaff;
} 

.checkbox-group-option-all {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
}

.checkbox-group-all-sign {
  font-size: 12px;
  color: #3ca0f5;
  cursor: pointer;
  border: none;
  padding: 0;
  z-index: 2;
}

.checkbox-group-all-sign.remove-button {
  color: #e50000;
  margin-right: 5px;
}
