.data-capture-table-wrapper-container {
  height: 100vh;
  position: relative;
}

.data-capture-table-wrapper {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 48px;
  bottom: 10px;
}

.data-capture-table-wrapper > * {
  float: left;
}

.data-capture-table-wrapper-column-container {
  height: 100%;
  overflow-x: hidden;
  background-color: #ffffff;
  flex: 1;
}

.data-capture-table-wrapper-column-container.column-container-6 {
  width: 16.5833%;
}

.data-capture-table-wrapper-column-container.column-container-5 {
  width: 19.9%;
}

.data-capture-table-wrapper .data-capture-table-column-data-list .checkbox-container .checkbox-label-container {
  padding-left: 0;
  width: 100%;
}

.data-capture-table-wrapper .data-capture-table-column-data-list .checkbox-group-option {
  padding: 0;
}

.data-capture-table-wrapper .data-capture-table-column-data-list .checkbox-container .checkbox-label-container .main-label {
  width: 100%;
  padding: 6px 18px;
}

.data-capture-table-wrapper .data-capture-table-column-data-list
.checkbox-container input[type="checkbox"] + .checkbox-label-container .main-label::after {
  content: none;
}

.data-capture-table-wrapper .data-capture-table-column-data-list
.checkbox-container input[type="checkbox"] + .checkbox-label-container .main-label::before {
    width: 100%;
    height: 29px;
    background: transparent;
    border: none;
}
