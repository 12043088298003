.data-capture-page-nav {
    width: 250px;
}

.data-capture-page-nav.fixed {
    position: fixed;
    top: 0;
}

.data-capture-page-nav-container {
    width: 270px;
    height: 1px;
}

.data-capture-page-nav-content {
    display: flex;
    padding-top: 17px;
    background-color: #eff2fd;
    min-height: 60vh;
    flex-direction: column;
    justify-content: space-between;
}

.data-capture-page-nav-title {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #417deb;
}

.data-capture-page-nav-list {
    width: 100%;
}

.data-capture-page-nav-controls {
    padding: 20px 19px;
}

.data-capture-page-nav-controls-section {
    width: 100%;
}

.data-capture-page-nav-controls-bottom-section-btn-container {
    width: 50%;
    display: inline-block;
    height: 32px;
    vertical-align: bottom;
    text-align: center;
}

.data-capture-page-nav-controls-bottom-section-btn-container:nth-child(odd) {
    padding-right: 7px;
}

.data-capture-page-nav-controls-bottom-section-btn-container:nth-child(even) {
    padding-left: 7px;
}


.placeholder-loading {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.placeholder-wrapper {
    padding: 6px 12px;
    background-color: #f0f4ff;
    border-radius: 4px;
    margin-bottom: 6px;
}

.placeholder-item {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(200, 200, 200, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    height: 16px;
    width: 100%;
    border-radius: 4px;
    animation: prefetchLoading 1.5s infinite;
    background-color: rgba(240, 240, 240, 0.5);
    position: relative;
    overflow: hidden;
}

@keyframes prefetchLoading {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.placeholder-header-wrapper {
    height: 80px;
    width: 100%;
    padding: 15px;
    background-color: #eff2fd;
    border-bottom: 1px solid rgba(218, 217, 223, 1);
}

.placeholder-header {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(200, 200, 200, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 100%;
    border-radius: 4px;
    animation: prefetchLoading 1.5s infinite;
    background-color: rgba(240, 240, 240, 0.5);
    position: relative;
    overflow: hidden;
}

@keyframes prefetchLoading {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

