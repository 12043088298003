.data-list {
  padding: 18px 62px
}

.list-container {
  padding-top: 12px;
}

.data-list .pagination {
  margin-top: 24px;
  margin-bottom: 24px;
}